import { LoadingScreen, useAppBridgeRouter } from '@segunosoftware/equinox';
import { Loading, Provider } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { useIsFetching } from '@tanstack/react-query';
import { Suspense, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import I18nProvider from './components/I18nProvider';
import ProtectedRoute from './components/ProtectedRoute';
import SentryWrapper from './components/SentryWrapper';
import SupportChat from './components/SupportChat';
import { useHostParam } from './hooks/useHostParam';
import { Dashboard, OAuth, Welcome } from './utils/lazy-load';

const SHOPIFY_APP_KEY = import.meta.env.VITE_SHOPIFY_KEY;

export default function App() {
	const location = useLocation();
	const host = useHostParam() ?? '';
	const router = useAppBridgeRouter();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	const config = useMemo(
		() => ({
			apiKey: SHOPIFY_APP_KEY,
			host,
			forceRedirect: !isOAuthPath
		}),
		[host, isOAuthPath]
	);
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<Provider config={config} router={router}>
				<SentryWrapper>
					<Suspense fallback={<LoadingScreen delay={500} />}>
						<Routes>
							<Route path="/oauth/*" element={<OAuth />} />
							<Route path="/*" element={<ProtectedRoute element={<AppEmbed />} />} />
						</Routes>
					</Suspense>
				</SentryWrapper>
			</Provider>
		</AppProvider>
	);
}

function AppEmbed() {
	const isFetching = useIsFetching();
	const calculatedLoading = isFetching > 0;
	return (
		<I18nProvider>
			<Suspense fallback={<LoadingScreen delay={500} />}>
				<Routes>
					<Route path="welcome" element={<Welcome />} />
					<Route path="" element={<Dashboard />} />
				</Routes>
			</Suspense>
			<SupportChat />
			{calculatedLoading && <Loading />}
		</I18nProvider>
	);
}
